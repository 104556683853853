import React from 'react';
import styles from './Slide.module.scss';
import Text from '../../../../../atoms/text/Text';
import doubleQuoteLeft from '../../../../../../assets/icons/doubleQuoteLeft.svg';
import doubleQuoteRight from '../../../../../../assets/icons/doubleQuoteRight.svg';
import { useTranslation } from 'react-i18next';
// import Backdrop from '../../../../../atoms/backdrop/Backdrop';
import students from '../../../../../../assets/images/testimonial.jpg';
import HtmlTag from '../../../../../molecule/htmlTag/HtmlTag';
import Backdrop from '../../../../../atoms/backdrop/Backdrop';

const Slide = ({
  image,
  title,
  description,
  date,
  dateTime,
  isTestimonial,
  hasBackdrop,
}) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const testimonialClass = isTestimonial ? styles['testimonial'] : '';

  return (
    <div
      // style={{
      //   backgroundImage: `url(${image})`,
      // }}
      className={`${styles['slide-one']} ${testimonialClass}`}
    >
      {hasBackdrop ? <div className={styles.backdrop}></div> : null}

      <div className={styles.image}>
        <img src={image} />
      </div>
      <div className={styles['container']}>
        {description ? (
          <div className={`${styles['students-info']}`}>
            <img
              className={styles['left-quotes']}
              src={doubleQuoteLeft}
              alt="dubai police"
              loading="lazy"
            />
            <img
              className={styles['right-quotes']}
              src={doubleQuoteRight}
              alt="dubai police"
              loading="lazy"
            />
            <div className={styles['right-border']}></div>
            <div className={styles['left-border']}></div>
            <div style={{ textAlign: isRtl ? 'right' : 'left' }}>
              <Text
                title={title}
                variant="text-bold-italic-20"
                style={{
                  textTransform: 'uppercase',
                }}
              />
            </div>

            <div className={styles['description']}>
              <HtmlTag
                title={description}
                variant="text-regular-18"
                maxLines={5}
                style={{
                  textAlign: isRtl ? 'right' : 'left',
                }}
              />
            </div>
            <div className={styles['footer']}>
              <Text title={date} variant="text-regular-16" />
              <Text title={dateTime} variant="text-regular-14" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Slide;
