import React, { useState, useEffect, Fragment } from 'react';
import styles from './Carousel.module.scss';
import leftArrow from '../../../assets/icons/carousel/left-arrow-head (1).svg';
import rightArrow from '../../../assets/icons/carousel/left-arrow-head.svg';

const Carousel = ({
  components = [],
  dots,
  hasArrows = true,
  style,
  startUpIndex,
  currentSlide,
  hasDots = true,
  className,
  slideClassName,
  nextStyle,
  prevStyle,
  StopSliderOnHover = false,
  containerClass,
}) => {
  const [currentIndex, setCurrentIndex] = useState(startUpIndex ?? 0);
  const [startX, setStartX] = useState(0);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const swipeLength = startX - endX;

    if (swipeLength > 50) {
      // Threshold for a "swipe left"
      setCurrentIndex((prev) => (prev + 1) % components.length);
    } else if (swipeLength < -50) {
      // Threshold for a "swipe right"
      setCurrentIndex(
        (prev) => (prev - 1 + components.length) % components.length
      );
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % components.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + components.length) % components.length
    );
  };

  useEffect(() => {
    if (currentSlide !== undefined && currentSlide !== currentIndex) {
      setCurrentIndex(currentSlide);
    }
  }, [currentSlide]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [currentIndex, components.length]);

  const handleKeydown = (event) => {
    if (event.keyCode === 37) {
      prevSlide();
    } else if (event.keyCode === 39) {
      nextSlide();
    }
  };

  return (
    <Fragment>
      <div
        className={`${styles['carousel-container']} ${className ?? ''} `}
        style={style}
        onTouchStart={StopSliderOnHover ? () => {} : handleTouchStart}
        onTouchEnd={StopSliderOnHover ? () => {} : handleTouchEnd}
      >
        <div
          className={`${styles['carousel']} ${containerClass ? containerClass : ''}`}
        >
          <div
            className={styles['carousel-track']}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            {components.map((Component, index) => (
              <div
                key={index}
                className={`${styles['carousel-slide']} ${slideClassName ?? ''} ${
                  index === currentIndex ? styles['active'] : ''
                }`}
              >
                {Component}
              </div>
            ))}
          </div>
          {hasArrows && (
            <>
              <button
                className={`${styles['carousel-arrow']} ${styles['prev']} ${nextStyle}`}
                onClick={prevSlide}
              >
                <img src={rightArrow} alt="Previous slide" />
              </button>
              <button
                className={`${styles['carousel-arrow']} ${styles['next']} ${prevStyle}`}
                onClick={nextSlide}
              >
                <img src={leftArrow} alt="Next slide" />
              </button>
            </>
          )}
        </div>
        {hasDots && components.length > 1 && (
          <div className={`${styles['carousel-dots']} ${dots}`}>
            {components.map((_, index) => (
              <span
                key={index}
                className={`${styles['dot']} ${
                  index === currentIndex ? styles.active : ''
                }`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Carousel;
