import React, { Fragment } from 'react';
import Text from '../../atoms/text/Text';
import styles from './Table.module.scss';
import Separator from '../../atoms/separator/Separator';
import { useTranslation } from 'react-i18next';

const semesterMapping2 = [
  { index: 'Semester_1', title: 'Semester 1', titleAR: 'الفصل الاول' },
  { index: 'Semester_2', title: 'Semester 2', titleAR: 'الفصل الاول' },
  { index: 'Semester_3', title: 'Semester 3', titleAR: 'الفصل الاول' },
  { index: 'Semester_4', title: 'Semester 4', titleAR: 'الفصل الاول' },
];

const MajorTable = ({ data, year, hasTitles }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';

  const sortedSemesterKeys = Object.keys(data).sort((a, b) => {
    const getSemesterNumber = (semesterStr) => {
      const match = semesterStr.match(/Semester_(\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    };

    return getSemesterNumber(a) - getSemesterNumber(b);
  });

  return (
    <div className={styles['table-container']}>
      <div className={styles['header']}>
        <Text
          title={year}
          variant="text-bold-20"
          style={{ textTransform: 'uppercase' }}
        />
        <Text
          title={Object.keys(data).reduce(
            (total, semesterKey) => total + data[semesterKey]?.courses?.length,
            0
          )}
          variant="text-bold-20"
          style={{ textTransform: 'uppercase' }}
        />
      </div>
      <table className={styles['table']}>
        {hasTitles && (
          <thead>
            <tr>
              <th
                className={`${styles.responsiveTH} ${isRtl ? styles.alignRight : ''}`}
                style={{ width: '100px' }}
              >
                <Text
                  title="Major.Semesters"
                  variant="text-bold-20-green"
                  style={{ textTransform: 'uppercase' }}
                />
              </th>
              <th
                className={`${styles.responsiveTH} ${isRtl ? styles.alignRight : ''}`}
              >
                <Text
                  title="Major.Course_Name"
                  variant="text-bold-20-green"
                  style={{ textTransform: 'uppercase' }}
                />
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {sortedSemesterKeys.map((semesterKey, semesterIndex) => (
            <Fragment key={semesterKey}>
              {data[semesterKey]?.courses?.map((major, majorIndex) => (
                <tr key={`${semesterIndex}-${majorIndex}`}>
                  {majorIndex === 0 ? (
                    <tr>
                      <td className={styles.responsiveTR}>
                        <Text
                          title={
                            currentLanguage === 'en'
                              ? semesterMapping2.find(
                                  (item) => item.index == semesterKey
                                ).title
                              : semesterMapping2.find(
                                  (item) => item.index == semesterKey
                                ).titleAR
                          }
                          variant="text-regular-18-black"
                          className={styles['semester-title']}
                        />
                      </td>
                    </tr>
                  ) : (
                    <tr></tr>
                  )}

                  <td className={styles.responsiveTR}>
                    <Text
                      title={
                        currentLanguage === 'en'
                          ? major.courseEN
                          : major.courseAR
                      }
                      variant="text-regular-18-black"
                    />
                  </td>
                </tr>
              ))}
              {Object.keys(data).length - 1 !== semesterIndex && (
                <tr>
                  <td colSpan="2">
                    <Separator
                      direction="horizontal"
                      style={{ height: '50px' }}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FeesTable = ({ data, fees, knowledge }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  return (
    <div className={styles['fees-table']}>
      <div
        className={styles['table-header']}
        style={{ backgroundColor: 'black' }}
      >
        <div
          className={`${styles['header-cell']} ${styles.hideOnPhone} ${
            isRtl ? 'rtl' : ''
          }`}
        >
          <Text
            title="Major.Title"
            variant="text-bold-20"
            className={styles.title}
          />
        </div>
        <div
          className={`${styles['header-cell']} ${styles.hideOnPhone} ${
            isRtl ? 'rtl' : ''
          }`}
        >
          <Text
            title="Major.Approved_Fees"
            variant="text-bold-20"
            className={styles.title}
          />
        </div>
        <div
          className={`${styles['header-cell']} ${styles.hideOnPhone} ${
            isRtl ? 'rtl' : ''
          }`}
        >
          <Text
            title="Major.Knowledge_and_Innovation_Dirham_Fees"
            variant="text-bold-20"
          />
        </div>
        <div
          className={`${styles['header-cell']} ${styles.showOnPhone} ${
            isRtl ? 'rtl' : ''
          }`}
        >
          <Text
            title="Major.PROGRAM_ANNUAL_FEES"
            variant="text-bold-20"
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>

      {data?.map((item, index) => (
        <div className={styles['table-row-wrapper']}>
          <div key={index} className={`${styles['table-row']}`}>
            <div
              className={`${styles['row-cell']} ${styles.hideOnPhone} ${
                isRtl ? 'rtl' : ''
              }`}
            >
              <Text
                title={item.title}
                variant="text-regular-18-black"
                className={styles.title}
              />
            </div>
            <div
              className={`${styles['row-cell']} ${styles.hideOnPhone} ${
                isRtl ? 'rtl' : ''
              }`}
            >
              <Text
                title={fees ?? item.approvedFees}
                variant="text-regular-18-black"
              />
            </div>
            <div
              className={`${styles['row-cell']} ${styles.hideOnPhone} ${
                isRtl ? 'rtl' : ''
              }`}
            >
              <Text
                title={knowledge ?? item.extraFees}
                variant="text-regular-18-black"
              />
            </div>
            <div
              className={`${styles['row-cell-container']} ${
                styles.showOnPhone
              } ${isRtl ? 'rtl' : ''}`}
            >
              <div
                className={`${styles['row-cell']} ${styles.showOnPhone} ${
                  isRtl ? 'rtl' : ''
                }`}
              >
                <Text title={item.title} variant="text-bold-20-black" />
              </div>
              <div
                className={`${styles['row-cell']} ${styles.showOnPhone} ${
                  isRtl ? 'rtl' : ''
                }`}
              >
                <Text
                  title={fees ?? item.approvedFees}
                  variant="text-regular-18-black"
                />
              </div>
              <div
                className={`${styles['row-cell']} ${styles.showOnPhone} ${
                  isRtl ? 'rtl' : ''
                }`}
              >
                <Text
                  title={knowledge ?? item.extraFees}
                  variant="text-regular-18-black"
                />
              </div>
            </div>
          </div>
          <div className={styles.separatorWrapper}>
            {index < data.length - 1 ? (
              <Separator direction="horizontal" style={{ height: '2rem' }} />
            ) : (
              <div className={styles.endLine}></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
const ProgramsTable = ({ data, title }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';

  return (
    <div className={styles['program-table']}>
      <div
        className={styles['table-header']}
        style={{ backgroundColor: 'black' }}
      >
        <div className={`${styles['header-cell']}  ${isRtl ? 'rtl' : ''}`}>
          <Text title={title} variant="text-bold-20" />
        </div>
      </div>

      {data?.map((item, index) => (
        <div className={styles['table-row-wrapper']}>
          <div
            key={index}
            className={`${styles['table-row']}`}
            style={{ marginTop: index === 0 ? '3rem' : '' }}
          >
            <div className={`${styles['row-cell']}  ${isRtl ? 'rtl' : ''} `}>
              <Text
                title={item.title}
                variant="text-regular-18-black"
                className={styles.programTitle}
              />
            </div>

            <div
              className={`${styles['row-cell']}  ${isRtl ? 'rtl' : ''} ${!Array.isArray(item.approvedFees) ? styles.removePadding : 'null'}`}
            >
              {Array.isArray(item.approvedFees) ? (
                <ul>
                  {item.approvedFees.map((titleItem, index) => (
                    <li key={index}>
                      <Text title={titleItem} variant="text-regular-20-black" />
                    </li>
                  ))}
                </ul>
              ) : (
                <Text
                  title={item.approvedFees}
                  variant="text-regular-20-black"
                />
              )}
            </div>
          </div>
          <div className={styles.separatorWrapper}>
            {index < data.length - 1 ? (
              <Separator direction="horizontal" style={{ height: '5rem' }} />
            ) : (
              <div className={styles.endLine}></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default FeesTable;
export { MajorTable, FeesTable, ProgramsTable };
