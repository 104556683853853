import React, { useState } from 'react';
import BottomNavigation from '../../../components/molecule/bottomNavigation/BottomNavigation';
import imageBackground from '../../../assets/images/home/common/primaryGirl/dummyImage.jpg';
import imageBackgroundMobile from '../../../assets/images/home/common/primaryGirl/dummyImage-mobile.jpg';

import styles from './MajorTemplate.module.scss';
import Text from '../../atoms/text/Text';
import TextWithSvg from '../../molecule/textWithSvg/TextWithSvg';
import time from '../../../assets/icons/majorDetails/time.svg';
import language from '../../../assets/icons/majorDetails/language.svg';
import calendar from '../../../assets/icons/majorDetails/calendar.svg';
import student from '../../../assets/icons/majorDetails/student.svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

function getLanguageText(languageCode, currentLanguage) {
  switch (languageCode) {
    case 0:
      return currentLanguage === 'en' ? 'English' : 'الإنجليزية';
    case 1:
      return currentLanguage === 'en' ? 'Arabic' : 'العربية';
    case 2:
      return currentLanguage === 'en'
        ? 'English and Arabic'
        : 'الإنجليزية والعربية';
    default:
      return 'English';
  }
}
function MajorTemplate({ handleButtonClick, navigationData, data }) {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const [bgImage] = useState(imageBackground);
  return (
    <>
      <div
        className={styles['major-container']}
        style={
          bgImage
            ? {
                backgroundImage: `url(${isMobile ? imageBackgroundMobile : bgImage})`,
              }
            : {}
        }
      >
        <div className={styles.backdrop}></div>
        <BottomNavigation
          data={navigationData}
          variant="text-bold-20"
          onClick={handleButtonClick}
          active={1}
          isApplyNow
        />
        <div className={styles['major-heading']}>
          <div className={`${styles['heading']} ${isRtl ? 'rtl' : ''}`}>
            <div className={styles['text']}>
              <Text
                title={
                  currentLanguage === 'en'
                    ? data?.degree?.degreeNameEn
                    : data?.degree?.degreeNameAr
                }
                variant="text-bold-italic-40"
                style={{ textTransform: 'uppercase' }}
              />
              {/* <Text
                title="LaW AND POLICE SCIENCES"
                variant="text-bold-italic-25"
                style={{ textTransform: 'uppercase' }}
              /> */}
            </div>
            <div className={styles['grid']}>
              <TextWithSvg
                svg={student}
                text="Major.entry_level"
                subText={
                  currentLanguage === 'en'
                    ? data?.degree?.entryLevelEn
                    : data?.degree?.entryLevelAr
                }
                variant="text-regular-20"
                subTextVariant={'text-bold-20-black'}
                imgStyle={styles.image}
              />
              <TextWithSvg
                svg={calendar}
                text="Major.Credit_Hours"
                subText={data?.degree?.creditHours}
                variant="text-regular-20"
                subTextVariant={'text-bold-20-black'}
                imgStyle={styles.image}
              />
              <TextWithSvg
                svg={time}
                text="Major.Duration"
                subText={
                  currentLanguage === 'en'
                    ? data?.degree?.durationEn
                    : data?.degree?.durationAr
                }
                variant="text-regular-20"
                subTextVariant={'text-bold-20-black'}
                imgStyle={styles.image}
              />
              <TextWithSvg
                svg={language}
                text="Major.Language"
                subText={getLanguageText(
                  data?.degree?.languages,
                  currentLanguage
                )}
                variant="text-regular-20"
                subTextVariant={'text-bold-20-black'}
                imgStyle={styles.image}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MajorTemplate;
