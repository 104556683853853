import React from 'react';
import Text from '../../atoms/text/Text';

import he from 'he';

const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const HtmlTag = ({
  title,
  variant,
  className,
  maxLines,
  color,
  fill,
  style,
  stripTags,
}) => {
  const divStyles = maxLines
    ? {
        ...style,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: maxLines,
        wordBreak: 'break-word',
        lineHeight: 2,
      }
    : { wordBreak: 'break-word', lineHeight: 2 };

  const content = stripTags ? stripHtmlTags(title) : title;

  return (
    <Text
      className={className}
      variant={variant}
      component={
        stripTags ? (
          <div style={divStyles}>{content}</div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: he.decode(content ?? '') }}
            style={divStyles}
          />
        )
      }
      color={color}
      fill={fill}
    />
  );
};

export default HtmlTag;
