import React from 'react';
import styles from './CourseCards.module.scss';
import svg from '../../../assets/icons/majors/diploma.svg';
import degreeHat from '../../../assets/icons/majorReq/hat.svg';
import book from '../../../assets/icons/majorReq/book.svg';
import degreeHatPC from '../../../assets/icons/majorReq/degreeHatPC.svg';
import diplomat from '../../../assets/icons/majorReq/diplomat.svg';

import { Card } from '../headingWithSubtext/HeadingWithSubtext';

const CourseCards = ({ data }) => {
  return (
    <div className={styles['course-cards']}>
      <div className={styles['cards']}>
        <Card
          variant="small"
          grid="grid-4"
          title={data?.totalGraduates}
          text="Major.Total_Graduates"
          svg={degreeHat}
          className={styles['header-classname']}
        />{' '}
        <div className={styles['separator']}></div>
        <Card
          variant="small"
          grid="grid-4"
          title={data?.requiredCourses}
          text="Major.Required_Courses"
          svg={degreeHatPC}
          className={styles['header-classname']}
        />
        <div className={styles['separator']}></div>
        <Card
          variant="small"
          grid="grid-4"
          title={data?.electiveCourses}
          text="Major.Elective_Courses"
          svg={book}
          className={styles['header-classname']}
        />
        <div className={styles['separator']}></div>
        <Card
          variant="small"
          grid="grid-4"
          title={data?.accreditation}
          text="Major.Accreditation"
          svg={diplomat}
          className={styles['header-classname']}
          imageStyle={styles.smallerImage}
        />
      </div>
    </div>
  );
};
const CourseCardsMobile = ({ data }) => {
  return (
    <div className={styles['course-cards-mobile']}>
      <div className={styles['cards-mobile-style']}>
        <Card
          variant="small"
          grid="grid-4"
          title={data?.totalGraduates}
          text="Major.Total_Graduates"
          svg={degreeHat}
          className={styles['card-mobile']}
        />
        <Card
          variant="small"
          grid="grid-4"
          title={data?.requiredCourses}
          text="Major.Required_Courses"
          svg={degreeHatPC}
          className={styles['card-mobile']}
        />
        <Card
          variant="small"
          grid="grid-4"
          title={data?.electiveCourses}
          text="Major.Elective_Courses"
          svg={book}
          className={styles['card-mobile']}
        />
        <Card
          variant="small"
          grid="grid-4"
          title={data?.accreditation}
          text="Major.Accreditation"
          svg={diplomat}
          className={styles['card-mobile']}
        />
      </div>
    </div>
  );
};
export { CourseCards, CourseCardsMobile };
