import React, { useEffect, Fragment } from 'react';
import styles from './Curiculum.module.scss';
import { HeadingWithSubtext } from '../../../../components/molecule/headingWithSubtext/HeadingWithSubtext';
import { MajorTable } from '../../../../components/molecule/table/Table';
import DownloadLottie from '../../../../assets/gifs/animation_lo2v9i9x.json';
import Lottie from 'lottie-web';
import List from '../../../../components/molecule/list/List';
import DownloadButton from '../../../../components/atoms/downloadButton/DownloadButton';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/atoms/text/Text';
import Separator from '../../../../components/atoms/separator/Separator';

const yearMapping = {
  1: 'years.First_Year',
  2: 'years.Second_Year',
  3: 'years.Third_Year',
  4: 'years.Fourth_Year',
  5: 'years.Fifth_Year',
};
const list = [
  'curiculum.introduction.1',
  'curiculum.introduction.2',
  'curiculum.introduction.3',
];

function Curiculum({ data = [] }) {
  useEffect(() => {
    const options = {
      container: document.querySelector('.lottie-container'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: DownloadLottie,
    };

    const animation = Lottie.loadAnimation(options);

    return () => {
      animation.destroy();
    };
  }, []);

  const organizedData = data.reduce((acc, course) => {
    const yearStr = yearMapping[course.year + 1];
    const yearKey = `${yearStr}`;
    const semesterKey = `Semester_${course.semesterInt + 1}`;

    if (!acc[yearKey]) acc[yearKey] = {};
    if (!acc[yearKey][semesterKey])
      acc[yearKey][semesterKey] = { title: semesterKey, courses: [] };
    acc[yearKey][semesterKey].courses.push(course);
    return acc;
  }, {});

  const sortedYearKeys = Object.keys(organizedData).sort((a, b) => {
    const getYearNumber = (yearStr) => {
      const yearNum = Object.keys(yearMapping).find(
        (key) => yearMapping[key] === yearStr
      );
      return parseInt(yearNum, 10);
    };

    const yearANum = getYearNumber(a);
    const yearBNum = getYearNumber(b);
    return yearANum - yearBNum;
  });

  return (
    <div className={styles['curiculum-container']}>
      <div>
        <HeadingWithSubtext
          variant="small"
          title="Major.Introduction_of_the_Curriculum"
          className={styles['container-classname']}
        />
        <List
          items={list}
          direction="column"
          style={{ gap: '0px', width: '100%' }}
          itemPaddingStart="0px"
          itemClassName={styles['items']}
        />
      </div>
      <div>
        <DownloadButton
          title="Major.download_guidline"
          variant="text-regular-16-black"
          className={styles.buttonClass}
          color="black"
        />
      </div>

      <div className={styles['tables']}>
        {sortedYearKeys.map((year) => (
          <MajorTable
            key={year}
            data={organizedData[year]}
            year={year}
            hasTitles
          />
        ))}
      </div>
    </div>
  );
}

export default Curiculum;
