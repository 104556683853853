import React, { useEffect, useState } from 'react';
import styles from './Overview.module.scss';
import { HeadingWithSubtext } from '../../../../components/molecule/headingWithSubtext/HeadingWithSubtext';
import {
  CourseCards,
  CourseCardsMobile,
} from '../../../../components/molecule/courseCards/CourseCards';
import StudentCard from '../../../../components/molecule/studentCard/StudentCard';
import { useNavigate } from 'react-router-dom';
import Carousel from '../../../../components/molecule/carousel/Carousel';
import { useMediaQuery } from 'react-responsive';
import ImageCarousel from '../../../../components/organism/imageCarousel/ImageCarousel';

import he from 'he';

function Overview({
  data,
  currentLanguage,
  outcomeData,
  facultyData,
  carouselData,
}) {
  const [slides, setSlides] = useState([]);

  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px) and (max-width: 1199px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 991px)',
  });
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' });
  const isTabletAndMobile = useMediaQuery({
    query: '(max-width: 1200px)',
  });

  let chunkSize;
  if (isLargeDesktop) {
    chunkSize = 4;
  } else if (isDesktop) {
    chunkSize = 3;
  } else if (isTablet) {
    chunkSize = 2;
  } else if (isPhone) {
    chunkSize = 1;
  }
  const navigate = useNavigate();
  const [programArrays, setProgramArrays] = useState([]);

  const navigateTo = (route) => {
    navigate(`/student/profile/${route.facultyId}`, {
      state: { student: route },
    });
  };

  useEffect(() => {
    var program = [];
    outcomeData?.map((item) => {
      var outcome = {
        svg: process.env.PUBLIC_URL + `/outcomeIcons/${item?.imageUrl}`,
        text: currentLanguage === 'en' ? item?.outComeEn : item?.outComeAr,
      };
      program.push(outcome);
    });
    setProgramArrays(program);
  }, [currentLanguage, outcomeData, data]);

  useEffect(() => {
    const getChunkSize = () => {
      if (isLargeDesktop) return 4;
      if (isDesktop) return 3;
      if (isTablet) return 2;
      return 1;
    };

    const chunkSize = getChunkSize();
    const newSlides = [];

    for (let i = 0; i < facultyData?.length; i += chunkSize) {
      newSlides.push(facultyData?.slice(i, i + chunkSize));
    }

    setSlides(newSlides);
  }, [isLargeDesktop, isDesktop, isTablet, facultyData]);

  return (
    <div className={styles['overview-container']}>
      <div className={styles['overview']}>
        <div className={styles.overviewGap}>
          <HeadingWithSubtext
            variant="small"
            title="Major.About_the_Program"
            subtext={
              currentLanguage === 'en'
                ? data?.aboutProgramEn
                : data?.aboutProgramAr
            }
            ishtml={true}
          />
          <HeadingWithSubtext
            variant="small"
            title="Major.Highlights"
            subtext={he.decode(
              currentLanguage === 'en' ? data?.highlightsEn : data?.highlightsAr
            )}
            className={styles['header-classname']}
            ishtml={true}
          />

          {isTabletAndMobile ? <CourseCardsMobile data={data} /> : null}
          <HeadingWithSubtext
            variant="small"
            title="Major.Admission_Requirements"
            subtext={he.decode(
              currentLanguage === 'en'
                ? data?.admissionRequirementsEn
                : data?.admissionRequirementsAr
            )}
            className={styles['header-classname']}
            ishtml={true}
          />

          <HeadingWithSubtext
            key={data}
            variant="small"
            title="Major.Program_Outcomes"
            grid="cards-grid"
            subtext="Major.requirements_text"
            subComponents={programArrays}
            className={styles['header-classname']}
          />
        </div>
        {isLargeDesktop && <CourseCards data={data} />}
      </div>
      {slides.length > 0 ? (
        <div className="dflex-container">
          <HeadingWithSubtext variant="small" title="Team.Faculty_Members" />
          <Carousel
            hasDots={false}
            style={{ minHeight: '100px', height: 'auto' }}
            nextStyle={styles.next}
            prevStyle={styles.prev}
            className={styles.carousel}
            components={slides.map((slideGroup, index) => (
              <div key={index} className={styles['students-grid']}>
                {slideGroup.map((faculty) => (
                  <StudentCard
                    key={faculty.facultyId}
                    img={faculty.url + faculty.facultyImage}
                    name={
                      currentLanguage === 'ar'
                        ? faculty.fullName_AR
                        : faculty.fullName_EN
                    }
                    email={faculty.email}
                    position={
                      currentLanguage === 'ar'
                        ? faculty.positionAR
                        : faculty.positionEN
                    }
                    isPhone={false}
                    onClick={() => navigateTo(faculty)}
                    imageId={faculty.facultyImage}
                  />
                ))}
              </div>
            ))}
          />
        </div>
      ) : null}

      {/* <div className={styles['students-grid']}>
        {facultyData?.map((faculty) => (
          <StudentCard
            key={faculty.facultyId}
            img={faculty.url + faculty.facultyImage}
            name={
              currentLanguage === 'ar'
                ? faculty.fullName_AR
                : faculty.fullName_EN
            }
            position={
              currentLanguage === 'ar' ? faculty.positionAR : faculty.positionEN
            }
            onClick={() => navigateTo(faculty)}
            isPhone={false}
          />
        ))}
      </div> */}
      <div>
        {carouselData.length > 0 ? (
          <HeadingWithSubtext variant="small" title="Major.testimonial" />
        ) : null}
        <ImageCarousel
          localData={carouselData}
          dotsClassname={styles.dotsClassname}
          slideClassName={styles.slider}
        />
        {/* <ProfileCarousel
          data={carouselData.map((data, index) => (
            <ProfileOne key={index} {...data} />
          ))}
        /> */}
      </div>

      {/* <HeadingWithSubtext
        variant="small"
        title="Related Programs"
        grid="grid-4"
        subComponents={[
          {
            svg: svg,
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          },
        ]}
        className={styles['header-classname']}
        isSeparated
      /> */}
    </div>
  );
}

export default Overview;
